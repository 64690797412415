<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    mounted() {
      if (process.env.NODE_ENV !== 'production') {
        require('dotenv').config();
      }
      console.log(process.env)
    },
  }
</script>
