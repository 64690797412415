import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    user: null,
    token: "",
    role: 0,
    notifications: 0,
    language: "",
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setRole(state, role) {
      state.role = role;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setLanguage(state, language) {
      state.language = language;
    },
  },
  actions: {
    submitData({ commit }, data) {
      commit("setUser", data.user);
      commit("setToken", data.token);
      commit("setRole", data.role);
      commit("setLanguage", data.language);
      // commit("setNotifications", data.notifications);
    }
  },
})
