// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from "axios";
import VueAxios from "vue-axios";
import lodash from "lodash";
import VueLodash from "vue-lodash";
import CommonUtil from "@/utils/commonutil.js";
import "./filters.js";
import vueSelectSides from "vue-select-sides";
import vueNumeralFilterInstaller from "vue-numeral-filter";

Vue.use(require("vue-moment"));
Vue.use(VueAxios, axios);
Vue.use(VueLodash, { lodash: lodash });
Vue.use(vueSelectSides, { locale: "zh_Hant" });
Vue.use(vueNumeralFilterInstaller, { locale: "chs" });
Vue.prototype.$common_util = CommonUtil;
Vue.config.productionTip = false

// check need login
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.token) {
      next({ name: "Login" });
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

// doing something with the response
const errorHandle = (status, msg) => {
  switch (status) {
    case 401:
      router.push({ name: "Login" }).catch(() => {});
      break;
    default:
      break;
  }
};

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { response } = error;
    if (response) {
      // 成功發出請求且收到 response, 但有 error
      errorHandle(response.status, response.data.error);
      return Promise.reject(error);
    } else {
      // 成功發出請求但沒收到 response
      return Promise.reject(error);
    }
  }
);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
