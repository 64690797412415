import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // login
        {
          name: "Login",
          path: "login",
          component: () => import("@/views/dashboard/pages/Login")
        },
        // Dashboard
        // {
        //   name: 'Dashboard',
        //   path: '',
        //   component: () => import('@/views/dashboard/Dashboard'),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        {
          name: 'User',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
          meta: {
            requiresAuth: true
          }
        },
        // Pages
        {
          name: 'AccountList',
          path: 'tables/account-list',
          component: () => import('@/views/dashboard/tables/AccountList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'RoleList',
          path: 'tables/role-list',
          component: () => import('@/views/dashboard/tables/RoleList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'PermissionList',
          path: 'tables/permission-list',
          component: () => import('@/views/dashboard/tables/PermissionList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'RolePermissionList',
          path: 'tables/role-permission-list',
          component: () => import('@/views/dashboard/tables/RolePermissionList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'SupplierList',
          path: 'tables/supplier-list',
          component: () => import('@/views/dashboard/tables/SupplierList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CustomerList',
          path: 'tables/customer-list',
          component: () => import('@/views/dashboard/tables/CustomerList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CustomerDeliverTypeRelationList',
          path: 'tables/customer-deliver-type-relation-list',
          component: () => import('@/views/dashboard/tables/CustomerDeliverTypeRelationList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CommodityList',
          path: 'tables/commodity-list',
          component: () => import('@/views/dashboard/tables/CommodityList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CustomsTypeList',
          path: 'tables/customs-type-list',
          component: () => import('@/views/dashboard/tables/CustomsTypeList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'DeliverTypeList',
          path: 'tables/deliver-type-list',
          component: () => import('@/views/dashboard/tables/DeliverTypeList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'DeliverSujectList',
          path: 'tables/deliver-suject-list',
          component: () => import('@/views/dashboard/tables/DeliverSujectList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CertificateList',
          path: 'tables/certificate-list',
          component: () => import('@/views/dashboard/tables/CertificateList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CertificateRelationList',
          path: 'tables/certificate-relation-list',
          component: () => import('@/views/dashboard/tables/CertificateRelationList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'KontainerList',
          path: 'tables/kontainer-list',
          component: () => import('@/views/dashboard/tables/KontainerList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'KontainerBoxList',
          path: 'tables/kontainer-box-list',
          component: () => import('@/views/dashboard/tables/KontainerBoxList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CustomerContainerBoxList',
          path: 'tables/customer-container-box-list',
          component: () => import('@/views/dashboard/tables/CustomerContainerBoxList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CustomsPriceList',
          path: 'tables/customs-price-list',
          component: () => import('@/views/dashboard/tables/CustomsPriceList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CustomsPriceDetail',
          path: 'tables/customs-price-detail',
          component: () => import('@/views/dashboard/tables/CustomsPriceDetail'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'DeliverFeeVerifyList',
          path: 'tables/deliver-fee-verify-list',
          component: () => import('@/views/dashboard/tables/DeliverFeeVerifyList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CustomDeliverFeeList',
          path: 'tables/custom-deliver-fee-list',
          component: () => import('@/views/dashboard/tables/CustomDeliverFeeList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CustomDeliverFeeDetail',
          path: 'tables/custom-deliver-fee-detail',
          component: () => import('@/views/dashboard/tables/CustomDeliverFeeDetail'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CustomDeliverFeeAfterList',
          path: 'tables/custom-deliver-fee-after-list',
          component: () => import('@/views/dashboard/tables/CustomDeliverFeeAfterList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CustomDeliverFeeAfterDetail',
          path: 'tables/custom-deliver-fee-after-detail',
          component: () => import('@/views/dashboard/tables/CustomDeliverFeeAfterDetail'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CustomDeliverFeeAfterPdfDetail',
          path: 'tables/custom-deliver-fee-after-pdf-detail',
          component: () => import('@/views/dashboard/tables/CustomDeliverFeeAfterPdfDetail'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'ReceiveList',
          path: 'tables/receive-list',
          component: () => import('@/views/dashboard/tables/ReceiveList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'ReceiveDetail',
          path: 'tables/receive-detail',
          component: () => import('@/views/dashboard/tables/ReceiveDetail'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'TaxPrimaryTypeList',
          path: 'tables/tax-primary-type-list',
          component: () => import('@/views/dashboard/tables/TaxPrimaryTypeList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'TaxSecondaryTypeList',
          path: 'tables/tax-secondary-type-list',
          component: () => import('@/views/dashboard/tables/TaxSecondaryTypeList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'DeliverFeeList',
          path: 'tables/deliver-fee-list',
          component: () => import('@/views/dashboard/tables/DeliverFeeList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'DocumentList',
          path: 'tables/document-list',
          component: () => import('@/views/dashboard/tables/DocumentList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'InvoiceList',
          path: 'tables/invoice-list',
          component: () => import('@/views/dashboard/tables/InvoiceList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'InvoiceDetail',
          path: 'tables/invoice-detail',
          component: () => import('@/views/dashboard/tables/InvoiceDetail'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'SystemEnv',
          path: 'tables/system-env-list',
          component: () => import('@/views/dashboard/tables/SystemEnvList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "LogEntryList",
          path: "tables/log-entry",
          component: () => import("@/views/dashboard/tables/LogEntryList"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "WarehouseList",
          path: "tables/warehouse-list",
          component: () => import("@/views/dashboard/tables/WarehouseList"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "PrepaymentList",
          path: "tables/prepayment-list",
          component: () => import("@/views/dashboard/tables/PrepaymentList"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "AdditionalFeeList",
          path: "tables/additional-fee-list",
          component: () => import("@/views/dashboard/tables/AdditionalFeeList"),
          meta: {
            requiresAuth: true
          }
        },
      ],
    },
  ],
})
