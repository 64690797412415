import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from 'vuetify/lib/locale/en'
import zhHans from "vuetify/lib/locale/zh-Hans";
import zhHant from "vuetify/lib/locale/zh-Hant";

Vue.use(VueI18n)

const messages = {
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en,
  },
  zhHans: {
    ...require("@/locales/zhHans.json"),
    $vuetify: zhHans
  },
  zhHant: {
    ...require("@/locales/zhHant.json"),
    $vuetify: zhHant
  },
}

export default new VueI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'zhHant',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'zhHant',
  messages,
})
